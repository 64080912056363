import { useContext } from "react";
import { AddTaskAndMobileMenuContext } from "../../../context/AddTaskAndMobileMenuContext";
import { HamburgerMenu } from "./HamburgerMenu/HamburgerMenu";
import { Title } from "./Title/Title";
import { ActualDate } from "./Date/ActualDate";

import "./HeaderLeft.css";

export const HeaderLeft = () => {
  const { setAddTaskIsOpen } = useContext(AddTaskAndMobileMenuContext);

  const showAddTask = () => {
    setAddTaskIsOpen(true);
  };

  return (
    <div className="HeaderLeft">
      <HamburgerMenu />
      <Title />
      <div className="HeaderLeft__calendar">
        <svg
          focusable="false"
          data-prefix="fas"
          data-icon="calendar-check"
          className="svg-inline--fa fa-calendar-check fa-w-14"
          role="img"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 448 512"
        >
          <path
            fill="white"
            d="M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z"
          />
        </svg>
        <h2 className="HeaderLeft__calendar-title">kalendarz</h2>
      </div>
      <ActualDate />
      <button className="Tasks__add-task-button" onClick={showAddTask}>
        dodaj zadanie
      </button>
    </div>
  );
};
